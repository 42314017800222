.searchbox {
    margin-top: 0.5rem;
    @include media-breakpoint-down(sm) {
        @include hidden();
    }
}

ul.tx-indexedsearch-browsebox.pagination {
    @extend .list-unstyled;
    li {
        display: inline;
        margin-right: 0.5rem;
    }
}
