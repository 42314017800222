.card {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    .group & {
        margin-bottom: 0;
    }

    .card-title {
        font-size: 1.25rem;
    }
    .card-header {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .card-img-top {
        flex-shrink: 0;
        height: auto;
    }
    .card-img-top img {
        height: auto;
        width: 100%;
        -ms-interpolation-mode: bicubic;
    }
    .lefticon.list-group-item {
        padding-left: 1.5rem;
        position: relative;
        &:before {
            position: absolute;
            left: 0.325rem;
            top: (15/16) * 1rem;
        }
    }

    .icongroup {
        padding-left: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin-bottom: 0.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .lefticon{
            position: relative;
            &:before {
                position: absolute;
                left: -1.25rem;
                top: (1/8) * 1rem;
            }
        }
    }
}