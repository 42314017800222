.jobPosting {
    border-color: $lightgrey;
    border-radius: 0;
    padding-left: 80px;
    .card-body {
        padding: 0.5rem;
        background-color: $lightgrey;
        min-height: 80px;

        @include media-breakpoint-up(md) {
            position: relative;
        }
    }
    &__image {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -40px;
        width: 80px;
        height: 80px;
    }
}