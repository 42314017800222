.footerMenu {

    line-height: 3rem;
    @include media-breakpoint-up(md) {
        line-height: 1.5rem;
    }


    .icon, .lefticon {
        &:before {
            @include hidden();
        }
    }
    header {
        margin-bottom: 1.5rem;
    }
}