%background {
    &--red {
        background-color: $streitred;
    }
    &--radialgreen {
        background-color: #9bc047;
        background: radial-gradient(ellipse 90% 100% at center top, #9bc047 0%, darken(#9bc047, 4%) 55%, lighten(#679a01, 4%) 62%, #679a01 100%);
    }
    &--radialgreen-active {
        background-color: darken(#9bc047, 10%);
        background: radial-gradient(ellipse 90% 100% at center top, darken(#9bc047, 10%) 0%, darken(#9bc047, 14%) 55%, darken(#679a01, 6%) 62%, #679a01 100%);
    }
}

.background--red {
    @extend %background--red;
}