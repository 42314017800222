.contactinfo {
    @extend .list-unstyled;
    @extend .list-inline;

    margin: 0;

    @include media-breakpoint-down(lg) {
        margin-top: 1rem;
    }
    @include media-breakpoint-down(md) {
        text-align: right;
    }


    li {
        @extend .list-inline-item;
    }
    a {
        text-decoration: underline;
    }
}