.languageMenu {
    @include media-breakpoint-down(sm) {
        @include hidden;
    }





    display: block;
    * {
        display: block;
    }


    position: absolute;
    z-index: $zindex-fixed;
    right: ($grid-gutter-width / 2) + 2px;
    top: 0;

    .menu__item {
        float:left;
        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}