.row-eq-height > div[class*='col-'] {
    display: flex;
    flex: 1 0 auto;
}
.row-eq-height .card {
    width: 100%;
}
/* ensure equal card height inside carousel */
.carousel-inner>.row-eq-height.active,
.carousel-inner>.row-eq-height.next,
.carousel-inner>.row-eq-height.prev {
    display: flex;
}
/* prevent flicker during transition */
.carousel-inner>.row-eq-height.active.left,
.carousel-inner>.row-eq-height.active.right {
    opacity: 0.5;
    display: flex;
}
.news-list-view .carousel {
    .card {
        p {
            margin-bottom: 0;
        }
    }




    &-switch {
        position: static;
        list-style: none;
        padding: 0;
        margin: 0;

        float:right;

        li {
            text-indent: 0;
            border-radius: 0.75rem;
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            text-align: center;
            font-size: 0.75rem;
            line-height: 1.5rem;
            padding: 0;
            background-color: $lightgrey;
            transition: background-color 0.3s, color 0.3s;
            float: left;
            margin: -0.5rem 0 (2/16 * 1rem) (1/4 * 1rem);
            &.active {
                @extend %background--radialgreen;
                color: $white;
            }
        }
    }
}