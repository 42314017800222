/*
#wrapper {
    @media (max-width: $screen-xs-max) {
        padding: 56px 0 0;
        @media (orientation: portrait) {
            padding-bottom: 79px;
        }
    }
}

#mm-blocker {
    z-index: $zindex-popover;
}

#mm-mmenu {
    @media (min-width: $screen-sm-min) {
        display: none !important;
    }
    color: $mmenu--textcolor;
    background-color: $mmenu--backgroundcolor;
    .current a:not(.mm-next) {
        background-color: $mmenu--focusbackgroundcolor;
        color: $mmenu--focustextcolor;
    }
    .mm-title {
        color: darken($mmenu--textcolor, 10%);
        font-weight: bold;
    }
    .mm-btn::before {
        border-color: $mmenu--textcolor;
    }
    .mm-next::after {
        border-color: $mmenu--textcolor;
    }
    .mm-btn::before {
        border-color: $mmenu--textcolor;
    }
    .sub_active > a.mm-next {
        background-color: $mmenu--focusbackgroundcolor;
        &::after {
            border-color: $mmenu--focustextcolor;
        }
    }
    .mm-search input {
        background-color: fade_out($mmenu__search--backgroundcolor, 0.4);
        color: black;
        &::-webkit-input-placeholder {
            color: fade_out($mmenu--textcolor, 0.2);
        }
    }
    .divider, .spacer {
        background-color: darken(desaturate($mmenu--backgroundcolor, 20%), 10%);
        padding: 1px 10px 2px 20px;
        color: $darkgrey;
        border-top: 1px solid darken(desaturate($mmenu--backgroundcolor, 20%), 20%);
        font-size: 80%;
        text-transform: uppercase;
    }
}

.offcanvas__control {
    @extend .clearfix;
}
*/

.mm__icon--active {
    background: rgba(255,255,255,.4);
}
.mm-iconbar {
    .menuitem {
        @extend .sr-only;
    }
}
.mm-listview {
    .menuitem {
        display: inline;
    }
}

.mm-iconbar .icon {
    padding: 9px 0;
}
/*
// Zu sehr im fokus
.mm-panels .mm-navbar {
    background-color: $streitred;
    .mm-navbar__title {
        color: $white;
    }
    .mm-btn:before {
        border-color: white;
    }
}
*/


