@include media-breakpoint-down(sm) {
    .mainMenu {
        @include hidden();
    }
}
.mainMenu {
    .menu {
        &__level {
            &--1 > .menu__item {
                &--open, &--active {
                    background-color: $focusred;
                }
                &--touched, &:hover {
                    background-color: $focusred;
                }
            }
            &--2 {
                border: 1px solid fade_out(#000000, 0.85);
                left: -1px;
                background-color: $focusred;
                border-radius: 0 0 5px 5px;
                padding-bottom: 5px;
                > .menu__item {
                    border-bottom: 1px solid $white;
                    &--touched, &:hover, &--open, &--active {
                        background-color: fade_out($white, 0.85);
                    }
                }
            }
        }
        &__item {
            a {
                text-decoration: none;
                color: $white;
                padding: (($line-height-base / 2) * 1rem);
                @include media-breakpoint-only(lg) {
                    padding: (($line-height-base / 2.8) * 1rem);
                }
                white-space: nowrap;
            }
            &--divider {
                display: none;
            }
        }
    }
    .icon-only--1 {
        .menuitem {
            @extend .sr-only;
        }
    }
}