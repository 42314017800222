@charset "utf-8";


//== Abstracts
// Import abstracts first, so we can use our variables in the custom vendor-modules
@import "Abstracts/functions";
@import "Abstracts/variables";
@import "Abstracts/mixins";


//== Vendors
// Import vendor-modules before everything else so we can override them.
@import "Vendors/a11y";
@import "Vendors/bootstrap";
@import "Vendors/mmenu";
@import "Vendors/hamburgers";


/*!
 * Streit-Template
 * @description Streit-Online CSS Template using Bootstrap, Hamburgers and mMenu
 * @author Sebastian Wolfertz
 * @site http://www.streit-online.de
 */


//== Base
// Import base styles to build upon
@import "Base/backgrounds";
@import "Base/type";
@import "Base/icons";
@import "Base/buttons";



//== Layout
// Menus, Grid, Header... the big layout stuff
@import "Layout/wrapper";
@import "Layout/header";
@import "Layout/navbar";
@import "Layout/carousel";
@import "Layout/content";
@import "Layout/footer";
@import "Layout/edge";


//== Components
// Small, self-contained components
@import "Components/menus";
@import "Components/contactInfo";
@import "Components/search";
@import "Components/mobileNavbar";
@import "Components/news";
@import "Components/card";
@import "Components/jobPosting";
@import "Components/googleMap";
@import "Components/copyright";
@import "Components/accordion";


//== Pages
// Import page-specific CSS after Layout and Components so we may override those


//== Themes
// Import Themes last so they override stuff


//== Shame
// Quick-Fixes and shameful Code to solve problems
@import "shame";
