.group > [role="tablist"] > .card > .card-header a {
    position: relative;
    &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: "";
        display: block;
        height: 1.2em;
        width: 1.2em;
        background-image: $carousel-control-next-icon-bg-dark;
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-position: center center;
        transform: rotate(90deg);
        transition: all 0.3s ease;
    }
    &[aria-expanded=true] {
        &:after {
            transform: rotate(-90deg);
        }
    }
}
