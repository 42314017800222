.menu {
    &--navbar & {
        &__level {
            &--1 {
                > .menu__item {
                    display: inline-block;
                    padding: 0;
                }

            }
            &--2 {
                border-radius: 0 0 5px 5px;
                /*
                 Internet Explorer does not regard list-style: none for elements with display: none in external CSS
                 display: none has been set inline to the element and display: block is called with !important
                 */
                //display: none;
                padding-bottom: 5px;
                position: absolute;
                top: 100%;
                z-index: $zindex-fixed;
            }
        }
        &__item {
            transition: background-color 300ms;
            position: relative;
            &--touched, &:hover {
                & > .menu__level {
                    display: block !important;
                }
            }
            &--opened > .menu__level {
                display: block;
            }
        }
    }
    &--horizontal & {
        &__level {
            display: inline-block;
            &--1 {
                > .menu__item {
                    display: inline-block;
                    padding: 0;
                }
            }
        }
    }
    &__level {
        @extend .list-unstyled;
        margin: 0;
    }
    &__item {
        @extend .list-unstyled;
    }
    a {
        transition: background-color 300ms;
        display: block;
    }
}