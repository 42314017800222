%icon {
    content: " ";
    display: inline-block;
    vertical-align: text-top;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include iconsize(1rem);
    @include iconextensions;
}

%bigicon {
    @include iconsize(1.5rem);
}

.icon, .lefticon {
    @include iconside(left) {
        @extend %icon;
    }
    @include iconize(left);
}

.lefticon {
    @include iconside(left) {
        margin-right: 2px;
    }
}
.righticon {
    @include iconside(right) {
        @extend %icon;
        margin-left: 2px;
    }
    @include iconize(right);
}

.icon--big:before, .lefticon--big:before, .righticon--big:after {
    @extend %bigicon;
}