
.header {
    //font-size: 85%;
    background-color: $header--background-color;
    padding: 15px 0 0;
    > .container {
        position: relative;

        & > .row {
            background-color: $header__container--background-color;
            border-radius: 6px 6px 0 0;
            margin: 0;
            padding: 15px 5px 5px;

            color: $white;
            a {
                color: white;
            }
        }
    }
    .logo {
        position: relative;
        padding-top: 27.813%;
        display: block;
        img {
            position: absolute;
            top: 0;
        }
    }
}

.header {
    @include media-breakpoint-down(sm) {
        @include hidden;
    }
}

