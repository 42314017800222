.footer {
    --cssloaded: 1;

    background-color: $darkgrey;
    color: $white;
    padding: 20px 0 0;
    margin-top: 1.5rem;
    font-size: 0.75rem;
    line-height: 1.5rem;

    .icon:before, .lefticon:before, .righticon:after {
        @include iconsize(0.75rem);
    }
    a:not(.btn, [role="button"]) {
        color: $white !important;
    }

    .row > div {
        margin-bottom: 3rem;
    }
}