@mixin hidden {
    /* http://www.456bereastreet.com/archive/200711/screen_readers_sometimes_ignore_displaynone/ */
    display: none;
    visibility: hidden;
}

@import "mixins/icons";


//  Background svg mixin
@mixin background-svg($svg){
    background-image: svg-url($svg);
}