.globalMenu {
    & .menu__level {
        &--1 {
            display: flex;
            flex-wrap: wrap;
        }
        &--2 {
            @include hidden;
        }
    }
    .menu__item {
        flex: 1 0 auto;
        text-align: center;
    }
    .menuitem__image {
        min-height: 48px;
        display: block;
        margin-bottom: 0;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
    a.icon:before, a.lefticon:before, a.righticon:after {
        display: none;
    }
}