.map_canvas {
    min-height: 500px;
    padding: 1rem;

    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 1rem;

    .googlenotice {
        display: block;
        margin-top: 2rem;
        text-align: center;

        .btn {
            margin-bottom: 0.5rem;
        }
    }
}