.mobileNavbar {
    @include media-breakpoint-up(md) {
        @include hidden();
    }

    position: fixed;
    z-index: $zindex-fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: auto;

    .logo {
        float: right;
        padding: 0.24rem;
        img {
            max-height: 100%;
            width: auto;
        }
        height: 55px;
        width: auto;
        display: block;
    }
}