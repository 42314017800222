@mixin iconside($side) {
    @if $side == right {
        &:after {
            @content;
        }
    } @else {
        &:before {
            @content;
        }
    }
}
@mixin iconextensions() {
    @each $color, $iconset in $icons {
        @each $name, $url in $iconset {
            .icons--#{$color} &--#{$name} {
                background-image: $url;
            }
        }
    }
}

@mixin iconsize($size) {
    height: $size;
    width: $size;
    @if ($size - 1rem) > 0 {
        margin-top: (1/16 * 1rem) - (($size - 1rem) / 2);
    }
}

@mixin iconize($side) {
    @each $name, $url in map-get($icons, nth(nth($icons, 1),1)){
        &--#{$name} {
            @include iconside($side){
                @extend %icon--#{$name};
            }
        }
    }
}