// Hamburger Overrides

// Settings
// ==================================================
$hamburger-padding-x: 15px;
$hamburger-padding-y: 12px;
$hamburger-layer-width: 40px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: white;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7; //0.7
$hamburger-hover-transition-duration: 0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (arrow, arrowalt);

@import "hamburgers/hamburgers";

// Own code

.hamburger {
    .hamburger-box {
        top: ($hamburger-layer-spacing / 2);
    }
    &:hover, &:focus, &:active {
        opacity: 1;
    }
}