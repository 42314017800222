$line-height-base: 1.5;

$white: #f1efee;
$lightgrey: #d4d4d4;
$darkgrey: #595959;
$streitred: #cc0000;
$textred: #b51619;
$focusred: #8e0606;
$lightgreen: #679a01;
$darkgreen: darken($lightgreen, 5%);
$lightblue: #97c7f4;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;



$header--background-color: $lightblue;
$header__container--background-color: $darkgrey;

$mmenu--textcolor: $streitred;
$mmenu--backgroundcolor: $white;
$mmenu--focusbackgroundcolor: $streitred;
$mmenu--focustextcolor: $white;
$mmenu__search--backgroundcolor: $lightgrey;

$text-color: $gray-700;

@import "variables/icons";

$glyph-size: 1rem * $line-height-base;
$flag-height: 12px;
$flag-width: 18px;