.slider {
    &__background {
        background-color: $lightgrey;

        .carousel-inner {
            padding-top: (470 / 1110) * 100%;
        }
        .carousel-item {
            position: absolute;
            top: 0;
        }
    }
}
