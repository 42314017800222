h3 {
    color: $darkgrey;
}

a:not(.btn, [role="button"]) {
    color: $streitred;
}

@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 1.5rem;
    }
    h2, .h2 {
        font-size: 1.25rem;
    }
    h3, .h3 {
        font-size: 1rem;
    }

    .h1, .h2, h1, h2, .h3, h3 {
        font-weight: 700;
    }
    .h4, .h5, h4, h5 {
        font-size: 1rem;
        font-weight: 700;
    }

    h4, .h4 {
        color: rgba($text-color, 0.75);
    }
    h5, .h5 {
        color: rgba($text-color, 0.6);
    }
}

a.external-link-new-window {
    @extend .righticon;
    @extend .righticon--external;
}

.mainContent ul {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.mainContent > div {
    margin-bottom: 2rem;
}

.flow-wrapper {
    margin: 0.5rem 0 1rem;
}

blockquote {
    border-left: 5px solid lightgrey;
    padding-left: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}

.form-check-label {
    margin-bottom: 0.25rem;
    input {
        margin-right: 0.5rem;
    }
}