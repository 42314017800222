.subMenu {
    @include media-breakpoint-down(sm) {
        @include hidden();
    }
    a {
        padding: 0.75rem 1rem;
        &:hover {
            background-color: lighten(desaturate($focusred, 50%), 20%);
            color: $white;
        }
        border-bottom: 1px solid $focusred;
    }
    .menu {
        &__level {
            &--2 {
                padding-left: 2rem;
            }
        }
        &__item {
            &--active {
                & > a {
                    background-color: $focusred;
                    color: $white;
                }
            }
        }
    }
    .icon:before, .lefticon:before {
        @include hidden;
    }
}